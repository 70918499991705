<template>
  <a-modal
    :visible="visible"
    :title="model.readOnly ? 'Lihat Artikel' : form.id ? 'Edit Artikel' : 'Tambahkan Artikel Baru'"
    @ok="handleOk"
    @cancel="handleCancel"
    :destroy-on-close="true"
    width="1000px"
  >
    <template #footer>
      <a-button key="back" :hidden="model.readOnly" @click="handleCancel">Cancel</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        :hidden="model.readOnly"
        @click="handleOk"
        >Submit</a-button
      >
    </template>
    <a-form :model="form" :rules="rules" class="myform" ref="formRef">
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Judul"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            has-feedback
            name="title"
          >
            <a-input
              width="100%"
              placeholder="Input Judul"
              v-model:value="title"
              :disabled="model.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Konten"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            has-feedback
            name="content"
          >
            <quill-editor
              class="mb-2"
              v-model:content="content"
              content-type="html"
              toolbar="full"
              :read-only="model.readOnly"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Kategori"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            has-feedback
            name="categories"
          >
            <a-input
              width="100%"
              placeholder="Input Kategori"
              v-model:value="categories"
              :disabled="model.readOnly"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Prioritas"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            has-feedback
            name="highlight"
          >
            <a-input-number
            style="width: 100%"
              v-model:value="highlight"
              :disabled="model.readOnly"
            />
          </a-form-item>
        </a-col>
      </a-row>
      
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Kustom Field 1"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            has-feedback
            name="cf1"
            label-color="black"
          >
            <a-input
              width="100%"
              v-model:value="cf1"
              :disabled="model.readOnly"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row" >
        <a-col :sm="{ span: 24, offset: 6 }" :md="{ span: 24, offset: 6 }">
              <a-checkbox
              v-model:checked="active"
              v-model:value="active"
              :disabled="model.readOnly"
          >
            <b>Aktif</b>
          </a-checkbox>
        </a-col>
      </a-row>
      <br>
      <a-row class="form-row">
        <a-col :sm="{ span: 24, offset: 6 }" :md="{ span: 24, offset: 6 }">
              <a-checkbox
            v-model:checked="is_public"
              v-model:value="is_public"
              :disabled="model.readOnly"
          >
            <b>Status Public</b>
          </a-checkbox>
        </a-col>
      </a-row>
      <br>
      <a-row class="form-row">
        <a-col :sm="{ span: 24, offset: 6 }" :md="{ span: 24, offset: 6 }">
              <a-checkbox
           v-model:checked="is_all_user"
            v-model:value="is_all_user"
            :disabled="model.readOnly"
          >
            <b>For All User</b>
          </a-checkbox>
        </a-col>
      </a-row>

    </a-form>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'

export default {
  props: {
    visible: [Boolean],
    model: {
      type: [Object],
      default: () => ({
        id: null,
        title: null,
        content: null,
        active: null,
        is_public: null,
        created_by: null,
        created_at: null,
        updated_by: null,
        updated_at: null,
        is_deleted: null,
        highlight: null,
        cf1: null,
        is_all_user: null,
        categories: null,
        record: {},
      }),
    },
  },
  emits: ['handleOk', 'update:visible'],
  setup(props, { emit }) {
    const form = reactive(Object.assign({}, props.model.record))

    const rules = {
      name: {
        required: true,
        message: 'Masukan Judul',
      },
    }
    const formRef = ref('')
    const errorMessage = ref(null)

    const handleOk = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }
      const url = `/api/articles${form.id ? '/' + form.id + '?_method=PUT' : ''}`
      apiClient
        .post(url, form)
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          message.success('Berhasil menyimpan')
          emit('handleOk', form, true)
        })
        .catch(async error => {
          message.error('Gagal menyimpan')
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
    }

    const handleCancel = e => {
      emit('update:visible', false)
    }

    return {
      form,
      ...toRefs(form),
      emit,
      rules,
      handleOk,
      handleCancel,
      formRef,
      errorMessage,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
